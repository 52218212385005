/* Add blur effect to the background */
.blur-background {
    filter: blur(5px);
  }
  
 /* Style for the modal */
.login_modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: transparent;
    width: 70%;
    height: 550px;
    display: flex; /* Use flexbox for layout */
    
}

/* Left half of the modal */
.login_left-half {
    flex: 0 0 55%; /* Set width to 55% */
    background-color: #f3efef;
    border-radius: 25px 0 0 25px;
}
.login_left-half img{
    margin-top: 20px;
    margin-left: 20px;
}

.login_left-half .content{
    width: 52%;
    margin: auto;
    text-align: center;
}

.login_left-half .content h1{
    color: #674f87;
    margin-top: 80px;
}

.login_left-half .icons{
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
}

.login_left-half .icons i{
    border: 1px solid #674f87;
    padding: 20px 10px 7px 10px;
    margin-right: 10px;
    border-radius: 50%;
}

.login_left-half .facebook-icon,.google-icon,.linkedin-icon{
    color: #674f87;
    font-size: 30px; 
}
.login_left-half .google-icon{
    font-size: 30px; 
}
.login_left-half .linkedin-icon{
    font-size: 30px; 
}

.login_left-half text{
    background-color: white;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px !important;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px !important;
}

.login_left-half .forgot-pass p{
    font-weight: bold;
    color: #9c9b9b;
}

.login_left-half .forgot-pass span{
    cursor: pointer;
    color: #674f87;
}

.login_left-half .forgot-pass span:hover{
    text-decoration: underline;
}

.login_left-half .loginbtn{
    background: linear-gradient(240deg, #f798a7, #674f87);
    padding: 10px 50px;
    border-radius: 15px;
}

.login_left-half .loginbtn:hover{
    background: linear-gradient(240deg, #674f87, #f798a7);
}

/* Right half of the modal */
.login_right-half {
    flex: 0 0 45%; /* Set width to 45% */
    background: linear-gradient(240deg, #674f87, #f798a7);
    border-radius: 0 25px 25px 0;
    color: #fff;
    text-align: center;
}

.login_right-half .heading p{
    line-height: 1.3;
    font-weight: bolder;
    font-size: 23px;
    margin-top: 100px;
}

.login_right-half .aggriment p{
    line-height: 1.3;
    font-weight: bolder;
    font-size: 15px;
}

.login_right-half button{
    background: none;
    margin-top: 20px;
    border: 1px solid #fff;
    border-radius: 15px;
    padding: 10px 80px 10px 80px;
    font-weight: bolder;
}

.login_right-half button:hover{
    background: linear-gradient(240deg, #674f87, #f798a7);
}


.password-input-container {
    position: relative;
}

.password-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

/* Responsive layout for the modal */
@media (max-width: 600px) {
    .modal {
        flex-direction: column;
        width: 90%;
    }

    .login_left-half,
    .login_right-half {
        flex: 1; /* Take full width on small screens */
        border-radius: 25px; /* Adjust border-radius for full width */
    }
}


