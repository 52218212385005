.cardContainer{
    background-color: #f5f5f5;
    width: 300px;
    height: 300px;
    border-radius: 20px;
    align-items: center;
    text-align: center;
}
.chartHeader h4{
    font-weight: bolder;
    text-align: center;
    line-height: 0px;
}
.outerCircle{
    background: linear-gradient(to bottom, #e4bc81, #ff6b6b);
    height: 230px;
    width: 230px;
    padding: 20px;
    border-radius: 50%;
    margin: auto;
}
.InnerCircle{
    background: #fff;
    border-radius: 50%;
    height: 190px;
    position: relative;
    background: #f5f5f5;
}
.subs-odometer {
    font-size:30px;
    font-weight:15px;
    text-align: center;
    padding: 8px;
    position: absolute;
    top: 35%;
    color: #ffffff;
    background: linear-gradient(to right, #ef798a, #674f87);
    border-radius: 25px;
}
